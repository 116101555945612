.swal-icon--warning__dot {
  width: 6px;
  height: 6px;
  margin-left: -3px;
}

.swal-icon--warning__body {
  width: 4px;
  height: 30px;
}

.swal-icon {
  width: 60px;
  height: 60px;
  margin: 0px auto;
}

.swal-title {
  font-size: 16px;
}

.swal-text {
  font-size: 13px;
  font-weight: 500;
}

.swal-footer {
  text-align: center;
  padding-top: 13px;
  margin-top: 0px;
  padding: 10px 10px;
}

.swal-modal {
  width: 430px;
}

.swal-modal-mobile {
  width: 300px;
}

.swal-icon:first-child {
  margin-top: 18px !important;
}

.swal-button--confirm {
  width: 58.5px;
}

.body-style {
  background: #293133;
  // overflow-x: hidden;
}

// .u-text {
//   word-wrap: break-word;
//   position: relative;
// }

.u-text-1 {
  font-weight: 600;
  font-size: 35px;
}

.u-text-2 {
  margin: 47px auto 0;
  font-size: 15px;
}

.card-title {
  font-size: 25px !important;
  display: flex;
  text-align: center;
  align-items: center;
}

.custom-radio {
  transform: scale(1.4);
  /* Increase the size as needed */
  cursor: pointer;
}

.specifyColor {
  accent-color: #91499d;
}

.radio-lable {
  // margin-left: 15px !important;
}

.card-border {
  border: 2px solid #91499d;
}

.card-border1 {
  border: 2px solid #dfe0e4;
}

.card {
  margin-bottom: 10px !important;
}

.our-plans-pra {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  margin-bottom: 1rem !important;
}

.swal-content {
  padding: 0 20px;
  margin-top: 20px;
  font-size: medium;
  height: 30px;
}